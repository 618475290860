import { ActionContext } from 'vuex'
import { userService } from '@/services/user.service';
import router from '../router';
import { AuthenticationState, LoginStatus } from '@/models/AuthenticationState'
import User from '@/models/User'
import LoginModel from '@/models/LoginModel'
import { State } from '.';

const ls = localStorage.getItem('user')
const user: User | null = ls == null ? null : JSON.parse(ls);
const initialState: AuthenticationState = user
    ? { status: LoginStatus.Logged, user }
    : { status: LoginStatus.NotLogged, user: null };

type Context = ActionContext<AuthenticationState, State>;

export const authentication = {
    namespaced: true,
    state: initialState,
    getters: {
        userName(state: AuthenticationState): string {
            return state.user?.username ?? ''
        },
        userRoles(state: AuthenticationState): string {
            return state.user?.userroles ?? ''
        },
        branchId(state: AuthenticationState): number | undefined {
            return state.user?.branchId 
        },
        isAuthenticated(state: AuthenticationState): boolean {
            return state.user != null
        }
    },
    actions: {
        login(context: Context, userCred: LoginModel ) {
            context.commit('loginRequest');

            userService.login(userCred.username, userCred.password)
                .then(
                    user => {
                        context.commit('loginSuccess', user);
                        router.push('/');
                    },
                    error => {
                        context.commit('loginFailure', error);
                        if (error =='Internal Server Error')
                            context.dispatch('alert/error', 'Oops! Something went wrong! Please send us an error report', { root: true });
                        else
                            context.dispatch('alert/error', 'Incorrect email or password', { root: true });
                    }
                );
        },
        logout(context: Context) {
            userService.logout();
            context.commit('logout');
        }
    },
    mutations: {
        loginRequest(state: AuthenticationState, username: string) {
            state.status = LoginStatus.InProgress
            state.user = null;
        },
        loginSuccess(state: AuthenticationState, user: User) {
            state.status = LoginStatus.Logged
            state.user = user;
        },
        loginFailure(state: AuthenticationState) {
            state.status = LoginStatus.NotLogged
            state.user = null;
        },
        logout(state: AuthenticationState) {
            state.status = LoginStatus.NotLogged
            state.user = null;
        }
    }
}
