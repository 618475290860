<template>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Actions') }}</h1>
            </div>
            <div class="col-4 text-right">
                <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{(data && data.totalRows) ? data.totalRows : 0}}</b-badge>
            </div>
        </div>
        <b-form class="mb-4">
            <b-form-row>
                <b-button :variant="hasFilters ? 'success' : 'primary'" v-b-toggle.collapse-3 class="mr-4">
                    {{
        $t('Show / Hide Filters')
                    }}
                </b-button>
            </b-form-row>
        </b-form>
        <b-collapse id="collapse-3">
            <b-card title="Filters" style="background-color: lightyellow">
                <b-form>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="budgetMin">Action Type</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-select v-model="filters.done">
                                <b-form-select-option :value="null">All</b-form-select-option>
                                <b-form-select-option :value="false">To do</b-form-select-option>
                                <b-form-select-option :value="true">Completed</b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="startDate">Date range</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-select v-model="filters.dateFilterType">
                                <b-form-select-option value="0">by date of creation</b-form-select-option>
                                <b-form-select-option value="1">by date of completion</b-form-select-option>
                                <b-form-select-option value="2">by date of plan</b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="startDate"
                                               reset-button
                                               v-model="filters.startDate"></b-form-datepicker>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="endDate"
                                               reset-button
                                               v-model="filters.endDate"></b-form-datepicker>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="employeeFilterType">Employee:</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-select v-model="filters.employeeFilterType" id="employeeFilterType">
                                <b-form-select-option value="0">created action</b-form-select-option>
                                <b-form-select-option value="1">assigned on action</b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col sm="6">
                            <field-account-search v-model="filters.employee" filter="2" :initialText="filters.employeeText" />
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-button variant="primary" v-on:click="applyFilters" class="mr-4">
                            {{
            $t('Apply Filters')
                            }}
                        </b-button>
                        <b-button variant="primary" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
                    </b-form-row>
                </b-form>
            </b-card>

        </b-collapse>
        <b-table :items="tableItems" :fields="fields" primary-key="id" :tbody-tr-class="rowClass"
                 sticky-header="70vh" :busy="isLoading">
            <template #cell(createdOn)="data">
                {{ data.item.createdOn | date }}
            </template>
            <template #cell(createdBy)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.createdById } }">
                    {{ data.item.createdBy }}
                </router-link>
            </template>
            <template #cell(assigned)="data">
                <router-link v-if="data.item.assigned" :to="{ name: 'Accounts/View', params: { id: data.item.assigned.id } }">
                    {{ data.item.assigned ? data.item.assigned.description: null }}
                </router-link>
            </template>
            <template #cell(doneDate)="data">
                {{ data.item.doneDate | date }}
            </template>
            <template #cell(date)="data">
                {{ data.item.date | date_short }}
            </template>
            <template #cell(links)="data">
                <router-link v-for="l in data.item.links"
                             v-bind:key="l.id"
                             :to="{ name: getEntityViewPath(l.entity), params: { id: l.id } }">
                    {{ l.description }}
                </router-link>
            </template>
            <template #cell(description)="data">
                {{ data.item.description }}
            </template>
            <template #cell(action)="row">
                <b-button v-if="editedRow == null" size="sm" @click="editRequest(row)" class="mr-2" variant="primary">
                    <font-awesome-icon icon="edit" />
                </b-button>
            </template>
            <template #row-details="row">
                <b-card title="Edit activity">
                    <activityEdit2 :id="row.item.id"></activityEdit2>
                </b-card>
            </template>
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
        </b-table>
        <b-row>
            <b-col sm="2" md="3" class="my-1">
                <b-form-group label="Per page"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-form-select id="per-page-select"
                                   v-model="filters.perPage"
                                   :options="pageOptions"
                                   size="sm"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1" v-if="data && data.totalRows > 0">
                <b-pagination v-model="filters.currentPage"
                              :total-rows="data ? data.totalRows : 0"
                              :per-page="filters.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import Multiselect from 'vue-multiselect'
    import { api } from '@/services/api'
    import { ISelectListItem } from '@/models/ISelectListItem'
    import { myutils } from '@/services/myutils'
    import FieldAccountSearch from '@/components/Shared/FieldAccountSearch.vue'
    import { EventBus } from '@/components/Shared/EventBus'
    import ActivityEdit2 from '@/components/Activity/ActivityEdit2.vue'


    @Component({ components: { ActivityEdit2, Multiselect, FieldAccountSearch } })
    export default class ActionList extends Vue {
        data: IData | null = null
        filters: IFilters = { dateFilterType: 0, done: null, employee: null, employeeFilterType: 0, endDate: null, startDate: null, employeeText: null, perPage: 20, currentPage: 1 }

        pageOptions = [5, 20, 100]

        isLoading = false

        created() {
            this.loadFilterFromCookies()
            this.fetchData()
            EventBus.$on('FieldAccountSearch_DisplayedText_Changed', this.setEmployeeText)
            EventBus.$on('ActivityEditCancelled', this.onActivityEditCancelled)
            EventBus.$on('ActivityEditSaved', this.onActivityEditSaved)
        }

        setEmployeeText(value: string) {
            this.filters.employeeText = value
        }

        loadFilterFromCookies(): void {
            const cookieFilter = this.$cookies.get('ActionList.filters') as IFilters
            if (!cookieFilter) return
            this.filters.currentPage = cookieFilter.currentPage ? cookieFilter.currentPage : 1
            this.filters.perPage = cookieFilter.perPage ? cookieFilter.perPage : 20
            this.filters.dateFilterType = cookieFilter.dateFilterType
            this.filters.done = cookieFilter.done
            this.filters.employee = cookieFilter.employee
            this.filters.employeeFilterType = cookieFilter.employeeFilterType
            this.filters.employeeText = cookieFilter.employeeText
        }

        fields = [
            'createdOn',
            'createdBy',
            'assigned',
            { key: 'links', label: 'Created at' },
            { key: 'date', label: 'Planned on' },
            { key: 'doneDate', label: 'Completed on' },
            'description',
            { key: 'action', label: '' }
        ]

        fetchData(): void {
            this.isLoading = true
            api
                .postExt<IFilters | null, IData>('/api/activity/actions', this.filters)
                .then((data) => {
                    this.data = data
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
        
        @Watch('filters.currentPage')
        onPageChanged(): void {
            if(this.data && this.data.totalRows > 0) {
                this.$cookies.set('ActionList.filters', this.filters)
                this.fetchData()
            }
        }

        @Watch('filters.perPage')
        onPerPageChanged(): void {
            if(this.data && this.data.totalRows > 0) {
                this.$cookies.set('ActionList.filters', this.filters)
                this.filters.currentPage = 1
                this.fetchData()
            }
        }

        getEntityViewPath(entity: number) {
            switch (entity) {
                case 2: {
                    return 'Accounts/View';
                }
                case 1: {
                    return 'Objects/View';
                }
                case 5: {
                    return 'Deals/Edit';
                }
                case 7: {
                    return 'Viewing';
                }
                case 6: {
                    return 'Reservations';
                }
            }
        }

        rowClass(item: IDataItem) { 
            if (!item) return ''
            return item.done ? 'completed' : 'todo' 
        }

        get hasFilters(): boolean {
            return this.filters.dateFilterType != 0 ||
                this.filters.done != null ||
                this.filters.employee != null ||
                this.filters.employeeFilterType != 0 ||
                this.filters.endDate != null ||
                this.filters.startDate != null
        }

        get dealTypeOptions(): ISelectListItem[] {
            return [{ text: 'All types of deals', value: null } as ISelectListItem, ...this.data?.options.types ?? []]
        }

        applyFilters(): void {
            this.filters.currentPage = 1
            this.$cookies.set('ActionList.filters', this.filters)
            this.fetchData()
        }

        clearFilters(): void {
            myutils.setPropertiesToNullOrEmptyArray(this.filters)
            this.filters.employee = null
            this.filters.dateFilterType = 0
            this.filters.employeeFilterType = 0
            this.applyFilters()
        }

        editedRow: any = null

        editRequest(row: any): void {
            this.editedRow = row
            this.editedRow.toggleDetails()
        }

        onActivityEditSaved(): void {
            this.editedRow.toggleDetails()
            this.editedRow = null
            this.fetchData()
        }

        onActivityEditCancelled(): void {
            this.editedRow.toggleDetails()
            this.editedRow = null
        }

        get tableItems() {
            return this.data?.items ?? []
        }
    }
    interface IData {
        items: IDataItem[],
        options: IFiltersOptions,
        totalRows: number
    }
    interface IDataItem {
        id: number
        done: boolean
        modifiedBy: string
        modifiedOn: string
    }
    interface IFilters {
        startDate: string | null
        endDate: string | null
        dateFilterType: number
        done: boolean | null
        employee: number | null
        employeeFilterType: number
        employeeText: string | null
        perPage: number
        currentPage: number
    }
    interface IFiltersOptions {
        types: ISelectListItem[]
        stages: ISelectListItem[]
    }
</script>
<style scoped>
</style>
